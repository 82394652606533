// Imports => React
import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { observer } from 'mobx-react-lite';
import { withStore } from '@src/stores';
import clsx from 'clsx';
import loadable from '@loadable/component';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsNumeric, AcIsUndefined } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-confirmation-modal',
  CONTENT: 'ac-confirmation-modal__content',
};

export const AcWizardContext = createContext({
  currentStepIndex: 0,
  currentStep: 0,
  totalSteps: 0,
  navigate: (step) => {},
  data: {},
  setData: (data) => {},
});

export const WizardProgress = ({ step, total }) => {
  return (
    <>
      {[...Array(total)].map((_, idx) => (
        <span
          key={idx}
          className={clsx(
            `ac-progress-item ${
              idx <= step ? `ac-progress-item--fill` : 'ac-progress-item--anim'
            }`
          )}
        ></span>
      ))}
    </>
  );
};

export const AcWizardStep = ({
  children,
  submitButton,
  previousButton,
  cancelButton,
  onCancel,
  hidden = false,
}) => {
  const { navigate, currentStepIndex, totalSteps } =
    useContext(AcWizardContext);

  const previousButtonOptions = useMemo(
    () => ({
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Previous',
      children: <span>Previous</span>,
      hidden: !currentStepIndex,
      ...previousButton,
      callback: previousButton?.callback
        ? () => previousButton.callback(navigate)
        : () => navigate(-1),
    }),
    [currentStepIndex, previousButton]
  );

  const submitButtonOptions = useMemo(
    () => ({
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Confirm',
      title: currentStepIndex + 1 === totalSteps ? 'Confirm' : 'Next',
      children: (
        <span> {currentStepIndex + 1 === totalSteps ? 'Confirm' : 'Next'}</span>
      ),
      ...submitButton,
      callback: submitButton?.callback
        ? () => submitButton.callback(navigate)
        : () => navigate(+1),
    }),
    [currentStepIndex, totalSteps, submitButton, submitButton?.disabled]
  );

  const cancelButtonOptions = useMemo(
    () => ({
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      callback: () => onCancel?.(),
      title: 'Cancel',
      children: <span>Cancel</span>,
      hidden: !!currentStepIndex,
      ...cancelButton,
    }),
    [currentStepIndex, cancelButton]
  );

  const getActionBarClassNames = useMemo(() => {
    return clsx('ac-wizard-modal--actionbar');
  });

  return (
    <>
      <AcContainer fluid>
        <AcRow className={'h-margin-y-5'}>
          <AcColumn className={'h-flex-v-align-left h-text--align-left'}>
            {children}
          </AcColumn>
        </AcRow>
      </AcContainer>
      <div className={getActionBarClassNames}>
        <AcRow className={'h-margin-top-40'}>
          <AcColumn className={'h-flex-v-align-right h-text--align-right'}>
            {!cancelButtonOptions?.hidden && (
              <span className={'h-margin-x-15'}>
                <AcButton {...cancelButtonOptions} />
              </span>
            )}
            {previousButtonOptions !== null &&
              !previousButtonOptions?.hidden && (
                <span className={'h-margin-x-15'}>
                  <AcButton {...previousButtonOptions} />
                </span>
              )}
            {submitButtonOptions !== null && !submitButtonOptions?.hidden && (
              <span className={'h-margin-left-15'}>
                <AcButton {...submitButtonOptions} />
              </span>
            )}
          </AcColumn>
        </AcRow>
      </div>
    </>
  );
};

const AcWizardModal = ({
  store: { ui },
  steps,
  progress,
  onStep,
  onSubmit,
  onCancel,
  className,
  loading = false,
}) => {
  const [currentStep, setCurrentStep] = useState(null);
  const [data, setData] = useState({});
  const [indexedSteps, setIndexedSteps] = useState([]);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN, 'ac-wizard-modal', className]);
  }, [className]);

  const getContentClassNames = useMemo(() => {
    return clsx(['ac-wizard-modal--content']);
  }, []);

  const closeWizard = () => {
    ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
    ui.reset(KEYS.MODAL);
  };

  const _onCancel = () => {
    // Close wizard if onCancel is not defined or true
    if (AcIsUndefined(onCancel) || onCancel === true) return closeWizard();

    // Close wizard if onCancel (async) function returns not false
    const cancelResult = onCancel();
    if (cancelResult === false) return;
    else if (cancelResult && typeof cancelResult.then === 'function') {
      cancelResult.then((value) => {
        if (value !== false) closeWizard();
      });
    } else closeWizard();
  };

  const indexSteps = () => {
    // Assign step number to child AcWizardStep element if not set
    const addIndexToSteps = (stepProps, index) => (
      <AcWizardStep
        {...{
          ...stepProps,
          step: AcIsNumeric(stepProps.step) ? stepProps.step : index,
          onCancel: _onCancel,
        }}
      />
    );
    const indexedSteps = steps.map(addIndexToSteps);
    setIndexedSteps(indexedSteps);
  };

  useEffect(() => indexSteps(), [steps]);

  const visibleSteps = useMemo(() => {
    return indexedSteps.filter(({ props: { hidden } }) => !hidden);
  }, [indexedSteps]);

  const currentChildIndex = useMemo(() => {
    // Select first visible child if currentStep is null
    const _currentStep =
      currentStep === null ? visibleSteps[0]?.props?.step : currentStep;
    return visibleSteps.findIndex(
      ({ props: { step } }) => step === _currentStep
    );
  }, [currentStep, visibleSteps]);

  const navigate = useCallback(
    (direction) => {
      const newChildIndex = currentChildIndex + direction;

      if (newChildIndex >= visibleSteps.length) {
        // Close wizard if onSubmit is not defined or true
        if (AcIsUndefined(onSubmit) || onSubmit === true) return closeWizard();

        // Close wizard if onSubmit (async) function returns not false
        const submitResult = onSubmit();
        if (submitResult === false) return;
        else if (submitResult && typeof submitResult.then === 'function') {
          submitResult.then((value) => {
            if (value !== false) closeWizard();
          });
        } else closeWizard();
      } else if (visibleSteps[newChildIndex]) {
        setCurrentStep(visibleSteps[newChildIndex].props.step);
      }
    },
    [currentChildIndex, visibleSteps]
  );

  useEffect(() => {
    const currentChild = visibleSteps[currentChildIndex];

    if (AcIsNumeric(currentChild?.props?.step)) {
      onStep?.(currentChild.props.step);
      ui.setValue(KEYS.MODAL, KEYS.TITLE, currentChild.props.title);
    }
  }, [currentChildIndex]);

  useEffect(() => {
    const renderProgress = progress ? (
      <WizardProgress step={currentChildIndex} total={visibleSteps.length} />
    ) : null;
    ui.setValue(KEYS.MODAL, KEYS.PROGRESS, renderProgress);
  }, [currentChildIndex, visibleSteps, progress]);

  const renderLoader = useMemo(() => {
    if (!loading) return null;

    return <AcLoader cover={true} loading={true} />;
  }, [loading]);

  return (
    <AcWizardContext.Provider
      value={{
        currentStepIndex: currentChildIndex,
        currentStep: visibleSteps[currentChildIndex]?.props?.step,
        totalSteps: visibleSteps.length,
        navigate,
        data,
        setData,
      }}
    >
      <div className={getStyleClassNames}>
        <div className={getContentClassNames} key={currentChildIndex}>
          {visibleSteps[currentChildIndex]}

          {renderLoader}
        </div>
      </div>
    </AcWizardContext.Provider>
  );
};

export default withStore(observer(AcWizardModal));
