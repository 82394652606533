// Imports => React
import React, {
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { forwardRef } from 'preact/compat';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
  DEFAULT_ROUTE,
  ICONS,
  KEYS,
  NAVIGATION_ROUTES,
  SIZES,
  THEMES,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsObject, AcIndicator, AcAfterTransitionEnd } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Atoms
import AcLogo from '@atoms/ac-logo/ac-logo.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
  MAIN: 'ac-navigation-wrp',
  VISIBLE: 'ac-navigation-wrp--visible',
  HAMBURGER: {
    MAIN: 'ac-hamburger',
    DOTS: 'ac-hamburger__dots',
    DOT: 'ac-hamburger__dot',
  },
  INDICATOR: {
    MAIN: 'ac-navigation__indicator',
    STATIC: 'ac-navigation__indicator--static',
    LEFT: 'ac-navigation__indicator--move-left',
    RIGHT: 'ac-navigation__indicator--move-right',
  },
  NAVIGATION: {
    MAIN: 'ac-navigation',
    SUB_LIST: 'ac-navigation__list ac-navigation__list--sub',
    TOGGLE: 'ac-navigation__toggle',
    LIST: 'ac-navigation__list',
    ITEM: 'ac-navigation__item',
    PARENT: 'ac-navigation__item--parent',
    ACTIVE_PARENT: 'ac-navigation__item--parent-active',
    OPEN_PARENT: 'ac-navigation__item--parent-open',
    LINK: 'ac-navigation__link',
    ACTIVE: 'ac-navigation__link--active',
    ICON: 'ac-navigation__icon',
    CHILDREN: {
      MAIN: 'ac-navigation-children',
      LIST: 'ac-navigation-children__list',
      ITEM: 'ac-navigation-children__item',
      LINK: 'ac-navigation-children__link',
      ACTIVE: 'ac-navigation-children__link--active',
      ICON: 'ac-navigation-children__icon',
    },
  },
};

let nav_delay = null;
let toggle_delay = null;
let navIndicator = null;
let unsubscribeFromStore = null;

const AcNavigation = forwardRef(
  ({ store: { auth, profile, ui }, routes = NAVIGATION_ROUTES }, ref) => {
    const navigate = useNavigate();
    const _location = useLocation();

    const { can, cannot } = usePermissions();
    const { current_roles } = profile;

    const $navigation = useRef(null);
    const $indicator = useRef(null);

    useEffect(() => {
      handleSubscribe(_location);

      return () => {
        // if (unsubscribeFromStore) unsubscribeFromStore();
      };
    }, [_location, navIndicator, $indicator, ref]);

    useEffect(() => {
      if (!AcIsSet(navIndicator)) {
        navIndicator = new AcIndicator(
          $navigation,
          $indicator,
          routes,
          _CLASSES.INDICATOR,
          true
        );
        navIndicator.init().then(init);
      } else if (AcIsSet(navIndicator) && AcIsSet(navIndicator.update)) {
        navIndicator.update(routes).then(init);
      }

      return () => {
        if (AcIsSet(navIndicator) && AcIsSet(navIndicator.unload)) {
          navIndicator.unload().then(() => {
            navIndicator = null;
          });
        }
      };
    }, [$indicator, $navigation, navIndicator, routes]);

    const getParent = (loc, item) => {
      const { parent, children } = item;
      const collection = children;
      const len = collection.length;
      let n = 0;
      let result = null;

      if (loc.pathname.indexOf(parent.path) === -1) return result;

      for (n; n < len; n++) {
        const item = collection[n];
        const { path } = item;

        if (loc.pathname.indexOf(path) > -1) {
          result = parent;
          break;
        }
      }

      return result;
    };

    const handleSubscribe = (route, action) => {
      let found = false;
      let n = 0;
      let len = routes.length;

      for (n; n < len; n++) {
        const item = routes[n];
        let active = false;

        const { parent, children } = item;

        if (!AcIsSet(parent) || !AcIsSet(children)) {
          active = item.path === route.pathname;
        } else if (AcIsSet(parent) && AcIsSet(children)) {
          active = getParent(route, item);
        }

        if (active) {
          found = AcIsObject(active) ? active : item;
          // handleClickEvent({}, found);
          break;
        }
      }

      if (!found && navIndicator) {
        navIndicator.reset();
      } else if (found && navIndicator) {
        navIndicator.recalculate({}, found);
      }
    };

    const init = () => {
      const len = routes.length;
      let n = 0;

      for (n; n < len; n++) {
        const route = routes[n];
        let active = false;

        const { parent, children } = route;

        if (route.path !== '/') {
          active =
            route.path === _location.pathname ||
            _location.pathname.indexOf(route.path) > -1;
        } else if (AcIsSet(parent) && AcIsSet(children)) {
          active = getParent(_location, route);
        } else {
          active = route.path === _location.pathname;
        }

        if (active) {
          const found = AcIsObject(active) ? active.$ref : route.$ref;
          if (found && found.click) {
            window.requestAnimationFrame(() => {
              setTimeout(() => {
                found.click();
              }, 200);
            });
          }
          break;
        }
      }

      if ($navigation && $navigation.current)
        AcAfterTransitionEnd($navigation.current, init);
    };

    const handleClickEvent = (event, route) => {
      if (route.callback) route.callback(event, route);
    };

    const handleToggle = useCallback(
      async (event) => {
        if (toggle_delay) clearTimeout(toggle_delay);
        if (event && event.preventDefault) event.preventDefault();
        if (event && event.stopPropagation) event.stopPropagation();

        const state = ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE];
        await ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, !state);
        if (!state) await ui.setValue(KEYS.NAVIGATION, KEYS.EXPANDED, []);
      },
      [
        ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE],
        ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED],
      ]
    );

    const handleHide = useCallback(
      async (event) => {
        if (toggle_delay) clearTimeout(toggle_delay);
        if (event && event.preventDefault) event.preventDefault();
        if (event && event.stopPropagation) event.stopPropagation();

        await ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, false);
        await ui.setValue(KEYS.NAVIGATION, KEYS.EXPANDED, []);
      },
      [
        ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE],
        ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED],
      ]
    );

    useImperativeHandle(ref, () => ({
      toggle: handleToggle,
      hide: handleHide,
    }));

    const handleMouseOver = useCallback(
      (event) => {
        if (toggle_delay) clearTimeout(toggle_delay);
        if (nav_delay) clearTimeout(nav_delay);

        if (!ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE]) {
          nav_delay = setTimeout(async () => {
            if (event && event.preventDefault) event.preventDefault();
            if (event && event.stopPropagation) event.stopPropagation();
            await ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, true);
          }, 600);
        }
      },
      [
        ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE],
        ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED],
      ]
    );

    const handleMouseLeave = useCallback(
      (event) => {
        if (toggle_delay) clearTimeout(toggle_delay);
        if (nav_delay) clearTimeout(nav_delay);
      },
      [ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE]]
    );

    const handleToggleParent = async (event, parent) => {
      if (toggle_delay) clearTimeout(toggle_delay);
      if (nav_delay) clearTimeout(nav_delay);

      let state = (ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED] || []).slice();
      const index = state.indexOf(parent.id);

      const active =
        _location.pathname && _location.pathname.indexOf(parent.path) > -1;

      if (active) return;

      if (state && index === -1) {
        state = [parent.id];
        await ui.setValue(KEYS.NAVIGATION, KEYS.EXPANDED, state);
        if (!ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE]) {
          ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, true);
        }
      } else if (state && index > -1) {
        state = [];
        await ui.setValue(KEYS.NAVIGATION, KEYS.EXPANDED, state);
      }
    };

    const handleLogoCallback = useCallback(() => {
      if (navigate) navigate(DEFAULT_ROUTE.path);
    });

    const getToggleClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.TOGGLE);
    });

    const getChildrenIconClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.CHILDREN.ICON);
    });

    const getChildrenActiveLinkClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.CHILDREN.ACTIVE);
    });

    const getChildrenLinkClassNames = (path) => {
      const active =
        _location.pathname && _location.pathname.indexOf(path) > -1;
      return clsx(
        _CLASSES.NAVIGATION.CHILDREN.LINK,
        active && _CLASSES.NAVIGATION.CHILDREN.ACTIVE
      );
    };

    const getChildrenItemClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.CHILDREN.ITEM);
    });

    const getChildrenListClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.CHILDREN.LIST);
    });

    const getChildrenClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.CHILDREN.MAIN);
    });

    const getIconClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.ICON);
    });

    const getActiveLinkClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.LINK, _CLASSES.NAVIGATION.ACTIVE);
    });

    const getLinkClassNames = (path) => {
      const active =
        (path !== '/' &&
          _location.pathname &&
          _location.pathname.indexOf(path) > -1) ||
        isActive(path, _location);

      return clsx(
        _CLASSES.NAVIGATION.LINK,
        active && _CLASSES.NAVIGATION.ACTIVE
      );
    };

    const getParentClassNames = useCallback(
      (path, id) => {
        const active =
          _location.pathname && _location.pathname.indexOf(path) > -1;
        const open =
          ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED].indexOf(id) > -1;
        return clsx(
          _CLASSES.NAVIGATION.ITEM,
          _CLASSES.NAVIGATION.PARENT,
          open && _CLASSES.NAVIGATION.OPEN_PARENT,
          active && _CLASSES.NAVIGATION.ACTIVE_PARENT
        );
      },
      [ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED], _location]
    );

    const getItemClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.ITEM);
    });

    const getListClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.LIST);
    });

    const getIndicatorClassNames = useMemo(() => {
      return clsx(_CLASSES.INDICATOR.MAIN);
    });

    const getNavigationClassNames = useMemo(() => {
      return clsx(_CLASSES.NAVIGATION.MAIN);
    });

    const getMainClassNames = useMemo(() => {
      return clsx(
        _CLASSES.MAIN,
        ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE] && _CLASSES.VISIBLE
      );
    }, [ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE]]);

    const isActive = (match, _location) => {
      return match !== null ? match === _location.pathname : false;
    };

    const renderChildren = (collection, parent) => {
      const len = collection.length;
      let n = 0;
      let result = [];

      for (n; n < len; n++) {
        const item = collection[n];

        if (can(item.allowed)) {
          const { id, label, icon, path } = item;

          const object = (
            <li
              key={`ac-navigation-item-${id}`}
              className={getChildrenItemClassNames}
              role={'presentation'}
              itemProp={'name'}
            >
              <NavLink
                to={path}
                className={getChildrenLinkClassNames(path)}
                title={`${parent} | ${label}`}
                itemProp={'url'}
                role={'menuitem'}
              >
                {icon && (
                  <AcIcon icon={icon} className={getChildrenIconClassNames} />
                )}
                {label}
                <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
              </NavLink>
            </li>
          );

          result.push(object);
        }
      }

      return result.length > 0 ? (
        <div className={getChildrenClassNames}>
          <ul
            className={getChildrenListClassNames}
            role={'menubar'}
            itemScope
            itemType={'http://www.schema.org/SiteNavigationElement'}
          >
            {result}
          </ul>
        </div>
      ) : null;
    };

    const renderParent = (item, children) => {
      const { id, label, path, icon } = item;

      const object = (
        <li
          key={`ac-navigation-item-${id}`}
          className={getParentClassNames(path, id)}
          role={'presentation'}
          itemProp={'name'}
        >
          <div
            className={getLinkClassNames(path)}
            title={label}
            onClick={(event) => handleToggleParent(event, item)}
            ref={(node) => {
              return (item.$ref = node);
            }}
          >
            {icon && <AcIcon icon={icon} className={getIconClassNames} />}
            {label}
            <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
          </div>

          {children && renderChildren(children, label)}
        </li>
      );

      return object;
    };

    const renderNavigationItem = (item) => {
      const { id, name, label, icon, path } = item;

      let _path = path;
      _path =
        name === 'Project Detail' ? _path.replace(':id', 'primary') : _path;

      const object = (
        <li
          key={`ac-navigation-item-${id}`}
          className={getItemClassNames}
          role={'presentation'}
          itemProp={'name'}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          ref={(node) => (item.$ref = node)}
        >
          <NavLink
            to={_path}
            className={getLinkClassNames(path)}
            title={label}
            itemProp={'url'}
            role={'menuitem'}
          >
            {icon && <AcIcon icon={icon} className={getIconClassNames} />}
            {label}
            <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
          </NavLink>
        </li>
      );

      return object;
    };

    const renderNavigation = useMemo(() => {
      const collection = routes;
      const len = collection.length;
      let n = 0;
      let result = [];

      for (n; n < len; n++) {
        const item = collection[n];

        const { parent, children } = item;

        let object;

        if (AcIsSet(parent) && AcIsSet(children)) {
          if (can(parent.allowed)) object = renderParent(parent, children);
        } else if (!AcIsSet(parent) || !AcIsSet(children)) {
          if (can(item.allowed)) {
            if (AcIsSet(item.not) && can(item.not)) {
              continue;
            } else {
              object = renderNavigationItem(item);
            }
          }
        }

        if (object) result.push(object);
      }

      return result;
    }, [_location, current_roles, ui[KEYS.CURRENT_NAVIGATION][KEYS.EXPANDED]]);

    const renderLogo = useMemo(() => {
      return <AcLogo callback={handleLogoCallback} />;
    });

    return (
      <div className={getMainClassNames} ref={$navigation} role={'navigation'}>
        <nav className={getNavigationClassNames}>
          {renderLogo}

          <ul
            className={getListClassNames}
            role={'menubar'}
            itemScope
            itemType={'http://www.schema.org/SiteNavigationElement'}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            {renderNavigation}
          </ul>

          <div className={getToggleClassNames} onClick={handleToggle}>
            <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
            <AcIcon icon={ICONS.CHEVRON_LEFT} />
          </div>

          <span ref={$indicator} className={getIndicatorClassNames} />
        </nav>
      </div>
    );
  }
);

AcNavigation.displayName = 'AcNavigation';

export default withStore(observer(AcNavigation));
