import React, { useMemo, useState, useRef, useEffect, memo } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { THEMES, SIZES, TYPES } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { useClickOutside, useEscapeKey } from '@hooks';

// Imports => Atoms
const AcSelectBox = loadable(() =>
	import('@atoms/ac-select-box/ac-select-box.web')
);
const AcTextInput = loadable(() =>
	import('@atoms/ac-text-input/ac-text-input.web')
);

const _CLASSES = {
	MAIN: 'ac-table-cell-inline',
	TOUCHED: 'ac-table-cell-inline--touched',
	ACTIVE: 'ac-table-cell-inline--active',
	ERROR: 'ac-table-cell-inline--error',
	NO_VALUE: 'ac-table-cell-inline--no-value',
};

const AcTableCellInline = ({
	row_id,
	field,
	object = null,
	initialValue,
	value,
	type,
	validate = () => {},
	callback = () => {},
	onTouch = () => {},
	active = false,
	disabled = false,
	className,
}) => {
	const [editMode, setEditMode] = useState(false);
	const [newValue, setNewValue] = useState(initialValue);
	const [validating, setValidating] = useState(false);
	const [touched, setTouched] = useState(false);
	const [hasError, setHasError] = useState(null);

	const _validateDelay = useRef(null);
	const _changeDelay = useRef(null);

	const { ref, setRef, visible, setVisible } = useClickOutside(false);
	const escapePressed = useEscapeKey(false);

	useEffect(() => {
		if (escapePressed) {
			setEditMode(false);
		}
	}, [escapePressed]);

	useEffect(() => {
		// Reset initialValue from outside
		if (initialValue !== newValue) {
			setNewValue(initialValue);
		}
	}, [initialValue]);

	useEffect(() => {
		if (value !== newValue) setNewValue(value);
	}, [value]);

	useEffect(() => {
		if (initialValue !== newValue) {
			setTouched(true);
		} else {
			setTouched(false);
		}
	}, [newValue]);

	useEffect(() => {
		if (!visible) {
			setEditMode(false);
		}
	}, [visible]);

	const handleKeyUp = (event) => {
		// const key = event.key || event.which;
		// const $active_element = document.activeElement;
		// const $inputs = ['input', 'select', 'button', 'textarea'];
		// if (
		// 	!$active_element ||
		// 	$inputs.indexOf($active_element.tagName.toLowerCase()) === -1
		// ) {
		// 	if (key === 'Enter') {
		// 		if (ref?.current) ref?.current.click();
		// 	}
		// }
	};

	const handleFocus = (event) => {
		// if (ref?.current) ref?.current.click();
		// const newEvent = new Event('mouseleave');
		// if (ref?.current) ref?.current?.dispatchEvent(newEvent);
	};

	const handleClick = (event) => {
		if (event?.persist) event.persist();
		if (event?.preventDefault) event.preventDefault();
		if (event?.stopPropagation) event.stopPropagation();

		if (disabled) return;

		setEditMode(true);
		setVisible(true);
	};

	const handleCallback = (event) => {
		if (event?.persist) event.persist();
		if (event?.preventDefault) event.preventDefault();
		if (event?.stopPropagation) event.stopPropagation();

		if (disabled) return;
		if (callback) callback(row_id, field);
	};

	const handleValidate = (event) => {
		if (event?.persist) event.persist();
		if (event?.preventDefault) event.preventDefault();
		if (event?.stopPropagation) event.stopPropagation();

		if (_validateDelay.current) clearTimeout(_validateDelay.current);
		if (disabled) return;

		_validateDelay.current = setTimeout(() => {
			if (validate)
				validate(row_id, { [field]: newValue })
					.then(() => setHasError(false))
					.catch(() => setHasError(true));
		}, 500);
	};

	const handleInputChange = async (event, name, changedValue, type) => {
		if (event && event.persist) event.persist();

		await handleValidate(event);

		if (!hasError) setNewValue(changedValue);
	};

	const handleInputBlur = async (event, name, changedValue, type) => {
		if (_changeDelay.current) clearTimeout(_changeDelay.current);

		if (hasError) {
			setNewValue(initialValue);
			setHasError(false);
			return;
		}

		_changeDelay.current = setTimeout(() => {
			if (initialValue !== changedValue) {
				if (onTouch)
					onTouch({
						row: row_id,
						field,
						value: changedValue,
						object,
						touched: true,
					});
			} else {
				if (onTouch)
					onTouch({
						row: row_id,
						field,
						value: initialValue,
						object,
						touched: false,
					});
			}
		}, 200);
	};

	const renderField = useMemo(() => {
		if (!AcIsSet(type))
			return (
				<span
					dangerouslySetInnerHTML={{
						__html: newValue,
					}}
				/>
			);

		if (type === 'string') {
			return (
				<AcTextInput
					name={field}
					type={TYPES.TEXT}
					value={newValue}
					callback={handleInputChange}
					onBlur={handleInputBlur}
					error={hasError}
					focus
				/>
			);
		} else if (type === 'number') {
			return (
				<AcTextInput
					name={field}
					type={TYPES.NUMBER}
					value={newValue}
					callback={handleInputChange}
					onBlur={handleInputBlur}
					error={hasError}
					focus
				/>
			);
		} else if (type === 'select') {
			return (
				<AcTextInput
					name={field}
					type={TYPES.NUMBER}
					value={newValue}
					callback={handleInputChange}
					onBlur={handleInputBlur}
					error={hasError}
					focus
				/>
			);
		}
	}, [value, newValue, hasError, type]);

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			active && _CLASSES.ACTIVE,
			touched && _CLASSES.TOUCHED,
			value === 'no-value' && _CLASSES.NO_VALUE,
			hasError && _CLASSES.ERROR,
			className
		);
	}, [touched, active, className, value, hasError]);

	return (
		<div
			ref={setRef}
			htmlFor={field}
			className={getMainClassNames}
			onClick={handleClick}
			// onKeyUp={handleKeyUp}
			onFocus={handleFocus}
			tabindex={0}
		>
			{!editMode && (
				<span
					dangerouslySetInnerHTML={{
						__html: touched ? newValue : value,
					}}
				/>
			)}
			{editMode && renderField}
		</div>
	);
};

export default memo(AcTableCellInline);
