// Imports => React
import React, {
	useEffect,
	useState,
	useMemo,
	forwardRef,
	useImperativeHandle,
} from 'react';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';

// Imports => Config
import config from '@config';

// Imports => Constants
import {
	ICONS,
	KEYS,
	MAP_CLUSTER_OPTIONS,
	MAP_OPTIONS,
	SIZES,
	THEMES,
	VISUALS,
} from '@constants';

// Imports => Utilties
import {
	AcIsSet,
	AcIsNull,
	AcGetMapMarkerImage,
	AcGenerateMapMarkerElement,
} from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-location-map-widget',
};

const AcLocationMapWidget = ({ location, entity }) => {
	const [mapInstance, setMapInstance] = useState(null);

	const renderMarker = (location, map, maps) => {
		const url = AcGetMapMarkerImage(entity);

		// Marker image
		// original size: 268 x  352

		const iconElement = AcGenerateMapMarkerElement(url, 32, 42, -16, -40.5);

		const position = new maps.LatLng(location.lat, location.lng);

		new maps.marker.AdvancedMarkerElement({
			position,
			content: iconElement,
			map,
		});
	};

	const init = ({ map, maps }) => {
		if (!AcIsSet(location)) return null;

		renderMarker(location, map, maps);

		const center = new maps.LatLng(location.lat, location.lng);
		map.setCenter(center);

		const styledMapType = new maps.StyledMapType(MAP_OPTIONS.styles);
		map.mapTypes.set('styled_map', styledMapType);
		map.setMapTypeId('styled_map');

		setMapInstance({ maps, map });
	};

	const renderMapWidget = useMemo(() => {
		if (!location) return null;

		const { maps_key: key } = config;
		return (
			<GoogleMapReact
				{...MAP_OPTIONS}
				bootstrapURLKeys={{
					key,
					libraries: ['places', 'markers', 'marker'],
					language: 'en',
					region: 'en',
				}}
				onGoogleApiLoaded={init}
			/>
		);
	}, [init, location]);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return <div className={getMainClassNames}>{renderMapWidget}</div>;
};

export default AcLocationMapWidget;
