import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, THEMES, SIZES, TYPES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsArray, AcGetClosestElement } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
  MAIN: 'ac-table-contextual-menu',
  OPEN: 'ac-table-contextual-menu--open',
  TOGGLE: {
    MAIN: 'ac-table-contextual-menu__toggle',
    ICON: 'ac-table-contextual-menu__toggle-icon',
  },
  DROPDOWN: {
    MAIN: 'ac-table-contextual-menu-dropdown',
    WRP: 'ac-table-contextual-menu-dropdown-wrp',
  },
  LIST: {
    MAIN: 'ac-table-contextual-menu__list',
    ITEM: 'ac-table-contextual-menu__item',
    LINK: 'ac-table-contextual-menu__link',
    DELETE: 'ac-table-contextual-menu__link--delete',
    LABEL: 'ac-table-contextual-menu__label',
    ICON: 'ac-table-contextual-menu__icon',
  },
};

const AcContextualMenu = ({ store: { ui }, id, data, field, actions }) => {
  const { current_table_contextual_menu } = ui;
  const $ref = useRef(null);
  let delay = null;

  useEffect(() => {
    if (current_table_contextual_menu.id === id) {
      if ($ref && $ref.current) {
        const parent = AcGetClosestElement($ref.current, '.ac-table__row');
        if (parent) {
          parent.addEventListener('mouseleave', handleClose, { passive: true });
        }
      }
    } else {
      if ($ref && $ref.current) {
        const parent = AcGetClosestElement($ref.current, '.ac-table__row');
        if (parent) {
          parent.removeEventListener('mouseleave', handleClose, {
            passive: true,
          });
        }
      }
    }
  }, []);

  const handleClose = () => {
    if (delay) clearTimeout(delay);
    delay = setTimeout(() => {
      if (current_table_contextual_menu.id === id)
        ui.setValue(KEYS.TABLE_CONTEXTUAL_MENU, KEYS.ID, null);
    }, 400);
  };

  const handleToggle = (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (current_table_contextual_menu.id === id)
      ui.setValue(KEYS.TABLE_CONTEXTUAL_MENU, KEYS.ID, null);
    else {
      ui.setValue(KEYS.TABLE_CONTEXTUAL_MENU, KEYS.ID, id);
    }
  };

  const getValue = (key) => {
    if (!data) return null;
    if (!key) return data;

    const len = data.length;
    let n = 0;
    let result = null;

    if (key === KEYS.OBJECT) {
      result = {};

      for (n; n < len; n++) {
        const item = data[n];
        const item_key = AcIsArray(item)
          ? !item[0]
            ? null
            : item[0].key
          : item.key;

        result[item_key] = AcIsArray(item) ? null : item.value;
      }
    } else {
      for (n; n < len; n++) {
        const item = data[n];
        const item_key = AcIsArray(item) ? item[0].key : item.key;

        if (item_key === key) {
          result = item;
          break;
        }
      }
    }

    return result;
  };

  const handleCallback = useCallback(
    (event, field, callback) => {
      if (event && event.persist) event.persist();
      if (event && event.preventDefault) event.preventDefault();
      if (event && event.stopPropagation) event.stopPropagation();
      let input = data;
      if (field === 'raw') {
        callback(input);
        return;
      } else if (field) {
        const item = getValue(field);
        if (item && item.value) input = item.value;
        else input = item;
      }
      if (callback) callback(input);
    },
    [data, actions]
  );

  const getIconClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.ICON);
  });

  const getLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.LABEL);
  });

  const getLinkClassNames = useCallback((type) => {
    return clsx(
      _CLASSES.LIST.LINK,
      type && type === TYPES.DELETE && _CLASSES.LIST.DELETE
    );
  });

  const getListItemClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.ITEM);
  });

  const getListClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.MAIN);
  });

  const getDropdownClassNames = useMemo(() => {
    return clsx(_CLASSES.DROPDOWN.MAIN);
  });

  const getDropdownWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.DROPDOWN.WRP);
  });

  const getToggleIconClassNames = useMemo(() => {
    return clsx(_CLASSES.TOGGLE.ICON);
  });

  const getToggleClassNames = useMemo(() => {
    return clsx(_CLASSES.TOGGLE.MAIN);
  });

  const getMainClassNames = useMemo(() => {
    const open = current_table_contextual_menu.id === id;
    return clsx(_CLASSES.MAIN, open && _CLASSES.OPEN);
  }, [id, ui.current_table_contextual_menu.id]);

  const renderActions = useMemo(() => {
    if (!actions || actions.length === 0) return null;

    const collection = actions;
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const group = collection[n];

      const grouplen = group.length;
      let b = 0;
      let groupresult = [];

      for (b; b < grouplen; b++) {
        const item = group[b];
        let { icon, label, field, callback, type, disabled, show } = item;
        let shouldRenderAction = true;
        if (show && typeof show === 'function') {
          shouldRenderAction = show(data);
        }

        const object = shouldRenderAction && (
          <li
            key={`ac-table-contextual-menu-item-${n}-${b}-${AcUUID()}`}
            className={getListItemClassNames}
            disabled={disabled}
          >
            <div
              className={getLinkClassNames(type)}
              onClick={(event) => {
                if (!disabled) handleCallback(event, field, callback);
              }}
            >
              <AcRipple
                theme={type === TYPES.DELETE ? THEMES.ALPHA : THEMES.PITCH}
                size={SIZES.SMALL}
                simple
              />
              {icon && <AcIcon icon={icon} className={getIconClassNames} />}
              <span
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
                className={getLabelClassNames}
              />
            </div>
          </li>
        );

        groupresult.push(object);
      }

      result.push(
        <ul
          key={`ac-table-contextual-menu-list-${n}-${AcUUID()}`}
          className={getListClassNames}
        >
          {groupresult}
        </ul>
      );
    }

    return result;
  }, [actions]);

  return (
    <div className={getMainClassNames} ref={$ref}>
      <div className={getToggleClassNames} onClick={handleToggle}>
        <AcIcon
          icon={ICONS.DOTS_VERTICAL}
          className={getToggleIconClassNames}
        />
        <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
      </div>

      <div className={getDropdownWrpClassNames}>
        <div className={getDropdownClassNames}>{renderActions}</div>
      </div>
    </div>
  );
};

export default withStore(observer(AcContextualMenu));
