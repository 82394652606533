import React, { useEffect, useRef, useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { THEMES, SIZES } from '@constants';

// Imports => Utilities
import { AcIsSet, AcIndicator } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-tab-bar',
	WRP: 'ac-tab-bar-wrp',
	INDICATOR: {
		MAIN: 'ac-tab-bar__indicator',
		STATIC: 'ac-tab-bar__indicator--static',
		LEFT: 'ac-tab-bar__indicator--move-left',
		RIGHT: 'ac-tab-bar__indicator--move-right',
	},
	TAB: {
		MAIN: 'ac-tab',
		ACTIVE: 'ac-tab--active',
		LABEL: 'ac-tab__label',
		ICON: 'ac-tab__icon',
	},
};

let tabIndicator = null;

const AcTabBar = ({ tabs = [], active = null, callback }) => {
	const $container = useRef(null);
	const $indicator = useRef(null);

	useEffect(() => {
		if (!AcIsSet(tabIndicator)) {
			tabIndicator = new AcIndicator(
				$container,
				$indicator,
				tabs,
				_CLASSES.INDICATOR
			);
			if (tabIndicator.init) {
				tabIndicator.init().then(init);
			}
		} else if (AcIsSet(tabIndicator) && AcIsSet(tabIndicator.update)) {
			tabIndicator.unload().then((instance) => {
				tabIndicator = instance.update(tabs);
				if (tabIndicator.init) {
					tabIndicator.init().then(init);
				}
			});
		}

		return () => {
			if (AcIsSet(tabIndicator) && AcIsSet(tabIndicator.unload)) {
				tabIndicator.unload().then(() => {
					tabIndicator = null;
				});
			}
		};
	}, [$indicator, $container, tabs]);

	const init = () => {
		let n = 0;
		let len = tabs.length;

		for (n; n < len; n++) {
			const tab = tabs[n];

			if (tab.id === active) {
				if (tab.$ref) {
					const $href = tab.$ref;
					if ($href)
						window.requestAnimationFrame(() => {
							setTimeout(() => {
								if (tab?.id) handleTabClick(tab.id);
								$href.click();
							}, 300);
						});
				}

				break;
			}
		}
	};

	const handleTabClick = useCallback(
		(id) => {
			if (callback) callback(id);
		},
		[callback]
	);

	const getLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.TAB.LABEL);
	});

	const getTabClassNames = useCallback(
		(id) => {
			const is_active = active && active === id;
			return clsx(_CLASSES.TAB.MAIN, is_active && _CLASSES.TAB.ACTIVE);
		},
		[active]
	);

	const getIndicatorClassNames = useMemo(() => {
		return clsx(_CLASSES.INDICATOR.MAIN);
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	});

	const getWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.WRP);
	});

	const renderTabs = useMemo(() => {
		const collection = tabs;
		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const item = collection[n];
			const { label, id, disabled } = item;

			const object = (
				<div
					key={`ac-tab-${id}`}
					ref={(node) => (item.$ref = node)}
					disabled={disabled}
					className={getTabClassNames(id)}
					onClick={() => handleTabClick(id)}
				>
					<AcRipple theme={THEMES.LIGHT} size={SIZES.SMALL} simple />
					{label && (
						<div
							className={getLabelClassNames}
							dangerouslySetInnerHTML={{
								__html: label,
							}}
						/>
					)}
				</div>
			);

			result.push(object);
		}

		return result;
	}, [tabs, active]);

	return (
		<div className={getWrpClassNames}>
			<div className={getMainClassNames} ref={$container}>
				{renderTabs}
				<span ref={$indicator} className={getIndicatorClassNames} />
			</div>
		</div>
	);
};

export default memo(AcTabBar);
