import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
	ICONS,
	KEYS,
	SIZES,
	SUB_NAVIGATION_ROUTES,
	THEMES,
	TITLES,
} from '@constants';

// Imports => Hooks
import { usePermissions, useUIActions } from '@hooks';

// Imports => Utilities
import { AcFormatInitials } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-sub-navigation',
	OPEN: 'ac-sub-navigation--open',
	INITIALS: 'ac-sub-navigation__initials',
	DETAILS: {
		MAIN: 'ac-sub-navigation__details',
		NAME: 'ac-sub-navigation__name',
		SUBLINE: 'ac-sub-navigation__subline',
		POINTER: 'ac-sub-navigation__pointer',
	},
	TOGGLE: {
		MAIN: 'ac-sub-navigation__toggle',
		ICON: 'ac-sub-navigation__toggle-icon',
	},
	DROPDOWN: {
		MAIN: 'ac-sub-navigation-dropdown',
		WRP: 'ac-sub-navigation-dropdown-wrp',
		LIST: 'ac-sub-navigation-dropdown__list',
		ITEM: 'ac-sub-navigation-dropdown__item',
	},
	NAVIGATION: {
		LIST: 'ac-sub-navigation__list',
		SUB_LIST: 'ac-sub-navigation__list--sub',
		ITEM: 'ac-sub-navigation__item',
		LINK: 'ac-sub-navigation__link',
		ACTIVE: 'ac-sub-navigation__link--active',
		ICON: 'ac-sub-navigation__icon',
	},
};

const AcSubNavigation = ({ store: { auth, profile, ui } }) => {
	const { current_profile, current_roles } = profile;
	const { current_sub_navigation } = ui;

	const $ref = useRef(null);

	const { can, cannot } = usePermissions();
	const { handleToggle, addEvents, removeEvents } = useUIActions(
		ui,
		KEYS.SUB_NAVIGATION,
		$ref
	);

	useEffect(() => {
		addEvents();

		return () => removeEvents();
	}, []);

	const handleViewChangelog = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();
	};

	const handleLogout = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();
		if (!auth.is_authorized) return;

		auth.logout();
	};

	const getCompany = useMemo(() => {
		if (!current_profile?.company?.name) return null;

		const { company } = current_profile;

		return company && company.name;
	}, [current_profile]);

	const getName = useMemo(() => {
		if (!current_profile?.name) return null;

		const { name } = current_profile;

		return name;
	}, [current_profile]);

	const getInitials = useMemo(() => {
		if (!current_profile?.name) return null;

		const { name } = current_profile;

		return AcFormatInitials(name);
	}, [current_profile]);

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ICON);
	});

	const getActiveLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LINK, _CLASSES.NAVIGATION.ACTIVE);
	});

	const getLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LINK);
	});

	const getItemClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ITEM);
	});

	const getSubListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST, _CLASSES.NAVIGATION.SUB_LIST);
	});

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST);
	});

	const getDropdownClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.MAIN);
	});

	const getDropdownWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.WRP);
	});

	const getPointerClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.POINTER);
	});

	const getSublineClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.SUBLINE);
	});

	const getNameClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.NAME);
	});

	const getDetailsClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.MAIN);
	});

	const getInitialsClassNames = useMemo(() => {
		return clsx(_CLASSES.INITIALS);
	});

	const getToggleIconClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.ICON);
	});

	const getToggleClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.MAIN);
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, current_sub_navigation.visible && _CLASSES.OPEN);
	}, [current_sub_navigation.visible]);

	const isActive = (match, location) => {
		return match !== null ? match.url === location.pathname : false;
	};

	const renderNavigationItem = ({ id, label, icon, path }) => {
		const object = (
			<li
				key={`ac-sub-navigation-item-${id}`}
				className={getItemClassNames}
				role={'presentation'}
				itemProp={'name'}
			>
				<NavLink
					to={path}
					className={getLinkClassNames}
					title={label}
					itemProp={'url'}
					role={'menuitem'}
				>
					{icon && <AcIcon icon={icon} className={getIconClassNames} />}
					{label}
					<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
				</NavLink>
			</li>
		);

		return object;
	};

	const renderNavigation = useMemo(() => {
		const collection = SUB_NAVIGATION_ROUTES;
		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const item = collection[n];

			const { parent, children } = item;

			let object;

			if (can(item.allowed)) object = renderNavigationItem(item);

			if (object) result.push(object);
		}

		return result;
	}, [current_roles]);

	return (
		<div className={getMainClassNames} ref={$ref}>
			<div
				className={getToggleClassNames}
				title={TITLES.YOUR_PROFILE_AND_SETTINGS}
				onClick={handleToggle}
			>
				<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
				<div className={getInitialsClassNames}>
					<Fade duration={300} key={getInitials}>
						{getInitials}
					</Fade>
				</div>
				<div className={getDetailsClassNames}>
					<Fade duration={300} key={getName}>
						<div
							className={getNameClassNames}
							dangerouslySetInnerHTML={{
								__html: getName,
							}}
						/>
					</Fade>
					{getCompany && (
						<Fade duration={300} key={getCompany}>
							<div
								className={getSublineClassNames}
								dangerouslySetInnerHTML={{
									__html: getCompany,
								}}
							/>
						</Fade>
					)}
				</div>
				<AcIcon icon={ICONS.CHEVRON_DOWN} className={getPointerClassNames} />
			</div>

			<div className={getDropdownWrpClassNames}>
				<div className={getDropdownClassNames}>
					<ul
						className={getListClassNames}
						role={'menubar'}
						itemScope
						itemType={'http://www.schema.org/SiteNavigationElement'}
					>
						{renderNavigation}
					</ul>

					<ul className={getSubListClassNames}>
						<li className={getItemClassNames}>
							<div className={getLinkClassNames} onClick={handleLogout}>
								<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
								<AcIcon
									icon={ICONS.LOGOUT_VARIANT}
									className={getIconClassNames}
								/>
								Log out
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default withStore(observer(AcSubNavigation));
