import React, { useEffect, useRef, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Files
import MIQIPPortalUserGuideForRentalCoordinators from '@assets/files/miqip-portal-user-guide-for-rental-coordinators-v1.1.pdf';

// Imports => Constants
import { ICONS, KEYS, SIZES, ROLES, THEMES, TITLES } from '@constants';

// Imports => Hooks
import { usePermissions, useUIActions } from '@hooks';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

const _CLASSES = {
	MAIN: 'ac-support-center',
	OPEN: 'ac-support-center--open',
	TOGGLE: {
		MAIN: 'ac-support-center__toggle',
		ICON: 'ac-support-center__toggle-icon',
	},
	DROPDOWN: {
		MAIN: 'ac-support-center-dropdown',
		WRP: 'ac-support-center-dropdown-wrp',
		TITLE: 'ac-support-center-dropdown__title',
		CONTENT: 'ac-support-center-dropdown__content',
	},
	NAVIGATION: {
		LIST: 'ac-support-center__list',
		SUB_LIST: 'ac-support-center__list--sub',
		ITEM: 'ac-support-center__item',
		LINK: 'ac-support-center__link',
		ACTIVE: 'ac-support-center__link--active',
		ICON: 'ac-support-center__icon',
	},
};

const AcSupportCenter = ({ store }) => {
	const { current_support_center } = store.ui;
	const $ref = useRef(null);

	const { is } = usePermissions();
	const { handleToggle, addEvents, removeEvents } = useUIActions(
		store.ui,
		KEYS.SUPPORT_CENTER,
		$ref
	);

	useEffect(() => {
		addEvents();

		return () => removeEvents();
	}, []);

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ICON);
	});

	const getLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LINK);
	});

	const getItemClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ITEM);
	});

	const getSubListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST, _CLASSES.NAVIGATION.SUB_LIST);
	});

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST);
	});

	const getDropdownContentClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.CONTENT);
	});

	const getDropdownTitleClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.TITLE);
	});

	const getDropdownClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.MAIN);
	});

	const getDropdownWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.WRP);
	});

	const getToggleIconClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.ICON);
	});

	const getToggleClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.MAIN);
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, current_support_center.visible && _CLASSES.OPEN);
	}, [current_support_center.visible]);

	return (
		<div className={getMainClassNames} ref={$ref}>
			<div
				className={getToggleClassNames}
				title={TITLES.SUPPORT_CENTER}
				onClick={handleToggle}
			>
				<AcIcon
					icon={ICONS.SUPPORT_AGENT}
					className={getToggleIconClassNames}
				/>
				<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
			</div>

			<div className={getDropdownWrpClassNames}>
				<div className={getDropdownClassNames}>
					<AcHeading rank={6} className={getDropdownTitleClassNames}>
						{TITLES.SUPPORT_CENTER}
					</AcHeading>
					<AcRichContent
						className={getDropdownContentClassNames}
						content={
							'<p>Do you have any <strong>questions</strong> or need <strong>support</strong> with the <strong>MIQIP Portal</strong>? Please contact us 24/7 on:</p>'
						}
					/>
					<ul className={getSubListClassNames}>
						<li className={getItemClassNames}>
							<a href={'tel:+31882081313'} className={getLinkClassNames}>
								<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
								<AcIcon
									icon={ICONS.PHONE_OUTLINE}
									className={getIconClassNames}
								/>
								+31(0)88 208 1313
							</a>
						</li>
						<li className={getItemClassNames}>
							<a
								href={'mailto:customerservice@iqip.com'}
								className={getLinkClassNames}
							>
								<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
								<AcIcon
									icon={ICONS.EMAIL_SEND_OUTLINE}
									className={getIconClassNames}
								/>
								customerservice@iqip.com
							</a>
						</li>
					</ul>

					{is(ROLES.RENTAL_COORDINATOR) && (
						<>
							<AcHeading rank={6} className={getDropdownTitleClassNames}>
								{TITLES.GUIDES}
							</AcHeading>
							<ul className={getSubListClassNames}>
								<li className={getItemClassNames}>
									<a
										href={MIQIPPortalUserGuideForRentalCoordinators}
										className={getLinkClassNames}
										download={'miqip-portal-user-guide-for-rental-coordinators'}
									>
										<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
										<AcIcon
											icon={ICONS.FILE_DOWNLOAD_OUTLINE}
											className={getIconClassNames}
										/>
										MIQIP Portal User Guide for Rental Coordinators
									</a>
								</li>
							</ul>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default withStore(observer(AcSupportCenter));
