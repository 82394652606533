import React, { useEffect, useCallback, useMemo, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { DEFAULT_ROUTE, ICONS, TYPES } from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatRole } from '@utils';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-impersonating-notice',
};

const AcImpersonatingNotice = ({ store: { auth, profile } }) => {
	const navigate = useNavigate();
	const { is_impersonating, current_impersonated } = auth;
	const { current_profile } = profile;

	const handleStopImpersonating = useCallback(async () => {
		await auth.stop_impersonating();
		await profile.who_am_i().then(() => {
			if (navigate) navigate(DEFAULT_ROUTE.path, { replace: true });
		});
	});

	const getStopImpersonatingButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			onClick: handleStopImpersonating,
		};
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const getName = useMemo(() => {
		if (!current_profile || !current_profile.name) return null;

		return current_profile.name;
	}, [current_profile]);

	const getCompanyAndRole = useMemo(() => {
		if (!AcIsSet(current_profile)) return null;

		const { company, roles } = current_profile;

		let result = '';
		if (company && company.name) result = company.name;
		if (roles && roles[0])
			result = `${result}${result ? ' | ' : ''}${AcFormatRole(roles[0])}`;

		return result;
	}, [current_profile]);

	const isReady = useMemo(() => {
		if (!is_impersonating) return false;
		if (!AcIsSet(current_impersonated)) return false;
		if (!AcIsSet(current_profile)) return false;

		const { id } = current_profile;
		const { user_id } = current_impersonated;

		return id === user_id;
	}, [is_impersonating, current_impersonated, current_profile]);

	return (
		<Fade direction={'top'} distance={'50px'} duration={250} reverse={!isReady}>
			<header className={getMainClassNames} id={'ac-impersonating-notice'}>
				<AcIcon icon={ICONS.NINJA} />
				You are impersonating
				<u>
					{getName && (
						<mark
							dangerouslySetInnerHTML={{
								__html: getName,
							}}
						/>
					)}
					{getCompanyAndRole && (
						<span
							dangerouslySetInnerHTML={{
								__html: `[${getCompanyAndRole}]`,
							}}
						/>
					)}
				</u>
				<button {...getStopImpersonatingButtonOptions}>
					Stop impersonating
				</button>
			</header>
		</Fade>
	);
};

export default withStore(observer(AcImpersonatingNotice));
