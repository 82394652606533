import React, { useMemo } from 'react';
import { useIqipAnalyticsSettings } from '../../hooks';
// Imports => Constants
import { ICONS, VARIANTS, THEMES, TYPES } from '@constants';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const openAnalytics = (url) => () => window.open(url, '_blank');

/**
 * React FC. Constructs an AcCard with a link to the analytics url.
 * When the given id equals the env variable projectId, the card is returned. Otherwise null.
 * @param {*} id - The project id
 * @param {*} render - The render prop for rendering the returned component.
 * @returns AcCard or null.
 */
export const AcIqipAnalyticsCard = ({ id, render }) => {
  const { projectId, link } = useIqipAnalyticsSettings();

  const renderCard = useMemo(() => {
    if (!projectId || !link) return null;
    if (projectId !== id) return null;
    const buttonOpts = {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'View piling plan',
      callback: openAnalytics(link),
    };
    return (
      <AcCard>
        <AcButton {...buttonOpts}>
          <AcIcon icon={ICONS.OPEN_IN_NEW} />
          <span>View piling plan</span>
        </AcButton>
        The piling plan will open in a new tab.
      </AcCard>
    );
  }, [id, projectId, link]);

  return render(renderCard);
};
