import React, { useEffect, useRef, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { AttentionSeeker } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, SIZES, THEMES, TITLES } from '@constants';

// Imports => Hooks
import { useUIActions } from '@hooks';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcContractNotification from '@atoms/ac-contract-notification/ac-contract-notification.web';

const _CLASSES = {
	MAIN: 'ac-message-center',
	OPEN: 'ac-message-center--open',
	EMPTY: 'ac-message-center--empty',
	TOGGLE: {
		MAIN: 'ac-message-center__toggle',
		ICON: 'ac-message-center__toggle-icon',
		BADGE: 'ac-message-center__badge',
	},
	DROPDOWN: {
		MAIN: 'ac-message-center-dropdown',
		WRP: 'ac-message-center-dropdown-wrp',
		TITLE: 'ac-message-center-dropdown__title',
		LIST: 'ac-message-center-dropdown__list',
		EMPTY: 'ac-message-center-dropdown__empty',
	},
};

const AcMessageCenter = ({ store }) => {
	const { current_message_center } = store.ui;
	const { current_notifications, current_notifications_count: count } =
		store.contracts;
	const $ref = useRef(null);

	const { handleToggle, addEvents, removeEvents } = useUIActions(
		store.ui,
		KEYS.MESSAGE_CENTER,
		$ref
	);

	useEffect(() => {
		addEvents();

		return () => removeEvents();
	}, []);

	const handleDismiss = ({ id }) => {
		if (!AcIsSet(id)) return;

		store.contracts.dismiss(id);
	};

	const getDropdownEmptyClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.EMPTY);
	}, []);

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.LIST);
	}, []);

	const getDropdownTitleClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.TITLE);
	}, []);

	const getDropdownClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.MAIN);
	}, []);

	const getDropdownWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.WRP);
	}, []);

	const getBadgeClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.BADGE);
	}, []);

	const getToggleIconClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.ICON);
	}, []);

	const getToggleClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.MAIN);
	}, []);

	const getMainClassNames = useMemo(() => {
		const empty = !count || count === 0;
		return clsx(
			_CLASSES.MAIN,
			empty && _CLASSES.EMPTY,
			current_message_center.visible && _CLASSES.OPEN
		);
	}, [count, current_message_center.visible]);

	const renderBadge = useMemo(() => {
		if (!AcIsSet(count) || count === 0) return null;

		return <span className={getBadgeClassNames}>{count}</span>;
	}, [count]);

	const renderEmptyMessage = useMemo(() => {
		return (
			<div className={getDropdownEmptyClassNames}>
				<AcIcon icon={ICONS.THUMB_UP_OUTLINE} />
				<AcHeading rank={6}>You're all caught up!</AcHeading>
				<p>
					Time to grab a coffee,
					<br />
					or stretch a little.
				</p>
			</div>
		);
	}, [getDropdownEmptyClassNames]);

	const renderMessages = useMemo(() => {
		if (!AcIsSet(current_notifications)) return renderEmptyMessage;

		const collection = current_notifications.slice();
		const len = collection.length;
		let n = 0;
		let result = [];

		if (collection.length === 0) return renderEmptyMessage;

		for (n; n < len; n++) {
			const item = collection[n];

			const object = (
				<AcContractNotification
					key={`ac-contract-notification--${item.id}`}
					notification={item}
					data={item.contract}
					dismiss={handleDismiss}
				/>
			);

			result.push(object);
		}

		return result;
	}, [current_notifications, renderEmptyMessage, handleDismiss]);

	const renderCount = useMemo(() => {
		const subject = count === 1 ? 'message' : 'messages';
		return (
			<sub>
				{count} {subject}
			</sub>
		);
	}, [count]);

	return (
		<div className={getMainClassNames} ref={$ref}>
			<AttentionSeeker effect={'rubberBand'} duration={600} spy={count}>
				<>{renderBadge}</>
			</AttentionSeeker>
			<div
				className={getToggleClassNames}
				title={TITLES.MESSAGE_CENTER}
				onClick={handleToggle}
			>
				<AcIcon icon={ICONS.BELL_OUTLINE} className={getToggleIconClassNames} />
				<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
			</div>

			<div className={getDropdownWrpClassNames}>
				<div className={getDropdownClassNames}>
					<AcHeading rank={6} className={getDropdownTitleClassNames}>
						{TITLES.MESSAGE_CENTER}
						{renderCount}
					</AcHeading>

					<div className={getListClassNames}>{renderMessages}</div>
				</div>
			</div>
		</div>
	);
};

export default withStore(observer(AcMessageCenter));
