// Imports => React
import React, {
	useEffect,
	useState,
	useMemo,
	useRef,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

const GoogleMapReact = loadable(() => import('google-map-react'));

// Imports => Config
import config from '@config';

// Imports => Constants
import {
	ICONS,
	KEYS,
	MAP_CLUSTER_OPTIONS,
	MAP_OPTIONS,
	PILELIST_MAP_OPTIONS,
	PERMISSIONS,
	SIZES,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilties
import {
	AcIsSet,
	AcIsNull,
	AcGetMapMarkerImage,
	AcGenerateMapMarkerElement,
} from '@utils';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web'));
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcEmptyPilelistBlock = loadable(() =>
	import('@atoms/ac-empty-pilelist-block/ac-empty-pilelist-block.web')
);

const _CLASSES = {
	MAIN: 'ac-pilelist-map-widget',
};

let _boundDelay = null;
let _positions = [];

const AcPileListMapWidget = ({
	store: { ui },
	location = MAP_OPTIONS.center,
	piles = [],
	is_busy = false,
	has_lists = true,
	callback,
	download,
}) => {
	const [mapInstance, setMapInstance] = useState(null);
	const [mapZoom, setMapZoom] = useState(17);
	const [marker, setMarker] = useState(null);
	const [pileMarkers, setPileMarkers] = useState([]);
	const [lines, setLines] = useState([]);
	const [displayPileMarkerLabels, setDisplayPileMarkerLabels] = useState(true);

	useEffect(() => {
		// if (AcIsSet(mapInstance) && AcIsSet(location)) {
		// 	const { map, maps } = mapInstance;
		// 	if (AcIsSet(marker) && marker.setPosition) {
		// 		const { lat, lng } = location;
		// 		const loc = new maps.LatLng(lat, lng);
		// 		marker.setPosition(loc);
		// 		map.panTo(loc);
		// 	}
		// }
	}, [location, piles]);

	const renderProjectMarker = (location, map, maps) => {
		const url = AcGetMapMarkerImage(KEYS.PROJECTS);

		// Marker image
		// original size: 268 x  352

		const iconElement = AcGenerateMapMarkerElement(url, 32, 42, -16, -40.5);
		// const icon = {
		// 	url, // url
		// 	scaledSize: new maps.Size(32, 42), // scaled size
		// 	origin: new maps.Point(0, 0), // origin
		// 	anchor: new maps.Point(16, 40.5), // anchor
		// };

		const position = new maps.LatLng(location.lat, location.lng);

		const m = new maps.marker.AdvancedMarkerElement({
			position,
			content: iconElement,
			map,
		});

		setMarker(m);
	};

	const renderPileMarker = (item, map, maps) => {
		const url = AcGetMapMarkerImage(KEYS.PILE);

		const iconElement = AcGenerateMapMarkerElement(
			url,
			16,
			16,
			-6,
			-6,
			displayPileMarkerLabels ? item?.name : null
		);
		// const icon = {
		// 	url, // url
		// 	size: new maps.Size(16, 16), // scaled size
		// 	scaledSize: new maps.Size(12, 12), // scaled size
		// 	origin: new maps.Point(0, 0), // origin
		// 	anchor: new maps.Point(6, 6), // anchor
		// 	labelOrigin: new google.maps.Point(6, -6), // label offset
		// };

		const lat = item?.lat ? item.lat : item?.latitude ? item.latitude : null;
		const lng = item?.lng ? item.lng : item?.longitude ? item.longitude : null;

		if (!lat || !lng) return false;

		const position = new maps.LatLng(lat, lng);

		const m = new maps.marker.AdvancedMarkerElement({
			position,
			content: iconElement,
			map,
			title: item.name,
		});

		return m;
	};

	const init = async ({ map, maps }) => {
		if (_boundDelay) clearTimeout(_boundDelay);
		if (!AcIsSet(location?.lat) || !AcIsSet(location?.lng)) return;

		const center = new maps.LatLng(location.lat, location.lng);
		const collection = piles || [];
		const len = collection.length;
		let n = 0;

		let bounds = new maps.LatLngBounds();
		let markers = [];
		// bounds.extend(center);

		renderProjectMarker(location, map, maps);
		const url = AcGetMapMarkerImage(KEYS.PILE);

		for (n; n < len; n++) {
			const item = collection[n];

			if (AcIsNull(item)) continue;

			const marker = renderPileMarker(item, map, maps);

			if (marker) {
				markers.push({
					marker: marker,
					label: item.name,
					position: marker.position,
				});

				let b = null;

				if (item?.lat && item?.lng) {
					b = new maps.LatLng(item.lat, item.lng);
				} else if (item?.latitude && item?.longitude) {
					b = new maps.LatLng(item.latitude, item.longitude);
				}

				if (AcIsSet(b)) bounds.extend(b);
			}
		}

		setPileMarkers(markers);

		maps.event.addListenerOnce(map, 'tilesloaded', () => {
			if (len > 0) {
				map.fitBounds(bounds);
			} else {
				map.setCenter(center);
			}
			setMapZoom(map.getZoom());

			maps.event.addListenerOnce(map, 'idle', () => {
				const zoomLevel = map.getZoom();
				if (zoomLevel > 13) {
					map.setZoom(13);
				}
			});

			const zoom = map.getZoom();
			if (zoom >= 12) {
				markers.forEach((m) => {
					if (map.getBounds().contains(m.marker.position)) {
						const iconElementWithLabel = AcGenerateMapMarkerElement(
							url,
							16,
							16,
							-6,
							-6,
							displayPileMarkerLabels ? m.marker.title : null
						);

						m.marker.content = iconElementWithLabel;

						// console.log('m', m);
						// m.marker.setLabel({
						// 	text: m.label,
						// 	color: '#4a4a4a',
						// 	fontSize: '10px',
						// 	className: 'pilelist-label',
						// });
					} else {
						const iconElementWithNoLabel = AcGenerateMapMarkerElement(
							url,
							16,
							16,
							-6,
							-6,
							false
						);

						m.marker.content = iconElementWithNoLabel;
					}
				});
			}
		});

		maps.event.addListener(map, 'zoom_changed', () => {
			const zoom = map.getZoom();

			if (zoom === mapZoom) return;

			const inBounds = [];
			const collection = markers;
			const len = collection.length;
			let n = 0;

			if (zoom >= 12) {
				for (n; n < len; n++) {
					const m = markers[n];

					if (map.getBounds().contains(m.position)) {
						const iconElementWithLabel = AcGenerateMapMarkerElement(
							url,
							16,
							16,
							-6,
							-6,
							m.marker.title
						);

						m.marker.content = iconElementWithLabel;

						inBounds.push(m.position);
					}
				}
			} else {
				for (n; n < len; n++) {
					const m = markers[n];

					if (map.getBounds().contains(m.position)) {
						const iconElementWithNoLabel = AcGenerateMapMarkerElement(
							url,
							16,
							16,
							-6,
							-6,
							false
						);

						m.marker.content = iconElementWithNoLabel;
					}
				}
			}

			setMapZoom(zoom);
		});

		maps.event.addListener(map, 'center_changed', () => {
			const zoom = map.getZoom();
			if (zoom >= 12) {
				markers.forEach((m) => {
					if (map.getBounds().contains(m.marker.position)) {
						const iconElementWithLabel = AcGenerateMapMarkerElement(
							url,
							16,
							16,
							-6,
							-6,
							m.marker.title
						);

						m.marker.content = iconElementWithLabel;
					} else {
						const iconElementWithNoLabel = AcGenerateMapMarkerElement(
							url,
							16,
							16,
							-6,
							-6,
							false
						);

						m.marker.content = iconElementWithNoLabel;
					}
				});
			}
		});

		const styledMapType = new maps.StyledMapType(MAP_OPTIONS.styles);
		map.mapTypes.set('styled_map', styledMapType);
		map.setMapTypeId('styled_map');

		setMapInstance({ maps, map });

		maps.event.trigger(map, 'zoom_changed');
	};

	const renderMapWidget = useMemo(() => {
		if (!location) return null;
		if (!piles) return null;

		const { maps_key: key } = config;
		return (
			<GoogleMapReact
				{...MAP_OPTIONS}
				bootstrapURLKeys={{
					key,
					language: 'en',
					region: 'en',
					libraries: ['marker'],
				}}
				onGoogleApiLoaded={init}
				key={btoa(`${JSON.stringify(location)}|${JSON.stringify(piles)}`)}
			/>
		);
	}, [init, location, piles, displayPileMarkerLabels]);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const renderEmptyBlock = useMemo(() => {
		if (!AcIsSet(has_lists) || !has_lists) {
			return <AcEmptyPilelistBlock callback={callback} download={download} />;
		}

		return null;
	}, [has_lists, callback, download]);

	return (
		<div className={getMainClassNames}>
			<AcCard dense className={'h-margin-y-0'}>
				<div className={'ac-pilelist-map-widget-wrp'}>{renderMapWidget}</div>

				<Fade key={has_lists}>
					<>{renderEmptyBlock}</>
				</Fade>
			</AcCard>
		</div>
	);
};

export default withStore(observer(AcPileListMapWidget));
